import Socials from "./Socials";

const Footer = () => {
  return (
    <>
      <div className="flex justify-between text-white bg-secondary text-text px-14 py-8">
        <div>Copyright © प्रyatna : All rights reserved</div>
        <Socials />
      </div>
    </>
  );
};

export default Footer;
