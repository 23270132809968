const EventCard = ({
  imageUrl,
  eventName,
  eventSubTitle,
  eventDescription,
}) => {
  return (
    <div class="flex flex-col w-2/3 md:w-5/12 lg:w-1/4 2xl:w-96 lg:min-w-18 hover:shadow-xl">
      <div>
        <img src={imageUrl} alt="event" />
      </div>
      <div class="p-8 shadow-md rounded-lg">
        <div class="mb-4">
          <span class="text-2xl 3xl:text-3xl font-semibold text-gray-700">
            {eventName}
          </span>
        </div>
        <p class="text-orange-500 font-semibold text-md 3xl:text-xl">
          {eventSubTitle}
        </p>
        <p class="text-gray-500 text-md 3xl:text-xl mb-6">{eventDescription}</p>
        <div className="text-base 3xl:text-xl cursor-pointer text-center w-full py-2 rounded-lg bg-orange-100 hover:bg-orange-500 text-gray-700 hover:text-white">
          View More
        </div>
      </div>
    </div>
  );
};

export default EventCard;
