const DocumentaryCTA = () => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center mx-14 text-justify mb-16">
      <div className="w-full sm:w-2/3 md:w-5/12">
        {/* <img src={hero} /> */}
        <iframe
          className="w-full h-80"
          src={`https://www.youtube.com/embed/bN15LUfyl9Y`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
      <div className="w-full md:w-1/2">
        Prayatna desires to work with the teachers of govt. schools in order to
        gain valuable experience and boost the social service agenda of the
        organisation. And,this will also inculcate the feeling of endowment in
        our volunteers. Moreover , we focus on teaching english and computer
        education through interactive and creative mediums so as to infuse the
        knowledge of these vital subjects in the students. Additionally, we also
        try to provide the students with artistic knowledge, general knowledge
        and awareness. The main objective of prayatna is to offer children
        enough idea of possible career paths that they can pursue in the future
        and be able to lead a prosperous life.
      </div>
    </div>
  );
};

export default DocumentaryCTA;
