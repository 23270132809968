import s1 from "../assets/images/photo gallery/s1.jpeg";
import s2 from "../assets/images/photo gallery/s2.jpeg";
import s3 from "../assets/images/photo gallery/s3.jpeg";
import h1 from "../assets/images/photo gallery/h1.jpeg";
import h2 from "../assets/images/photo gallery/h2.jpeg";
import h3 from "../assets/images/photo gallery/h3.jpeg";
import h4 from "../assets/images/photo gallery/h4.jpeg";
import h5 from "../assets/images/photo gallery/h5.jpeg";
import v1 from "../assets/images/photo gallery/v1.jpeg";

export const photoGallery = [
  {
    // heading : 'This is a Heading',
    // description : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Pariatur sed dolorem delectus ratione facilis, voluptate odio dolor assumenda qui provident ipsum expedita tempora illum, omnis harum quibusdam quis ex tenetur!',
    imageUrl: s1,
    type: "square",
  },
  {
    imageUrl: h3,
    type: "horizontal",
  },
  {
    imageUrl: s2,
    type: "square",
  },

  {
    imageUrl: h1,
    type: "horizontal",
  },
  {
    imageUrl: h2,
    type: "horizontal",
  },

  {
    imageUrl: h4,
    type: "horizontal",
  },
  {
    imageUrl: h5,
    type: "horizontal",
  },
  {
    imageUrl: s3,
    type: "square",
  },
  {
    imageUrl: v1,
    type: "square",
  },
];
