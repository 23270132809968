import hero from "../assets/hero.jpg";
import { useContext } from "react";
import { AppContext } from "../App";
import { ReactComponent as RightArrow } from "../assets/right-arrow.svg";

const HomeHero = () => {
  const { openContactModal } = useContext(AppContext);
  return (
    <div className="flex flex-col md:flex-row justify-between items-center mx-8 sm:mx-14">
      {/* Left */}
      <div className="w-full md:w-1/2 py-16">
        <div className="text-4xl lg:text-5xl mb-6 leading-normal font-semibold">
          Educating all in this
          <span className="text-5xl lg:text-6xl text-orange-500"> Era</span>
        </div>
        <div className="text-lg text-justify">
          प्रyatna is a non profitable organisation which focuses on providing
          sound education to the students of govt. schools through interactive
          medium. It is an initiative taken by SVVV, Indore which is managed by
          the students and faculties of SVVV
        </div>
        <div className="flex justify-center bg-gray-700 hover:bg-orange-600 cursor-pointer text-white rounded-full w-52 my-4 py-2">
          <span className="mr-1.5" onClick={openContactModal}>
            Contact US
          </span>
          <RightArrow className="w-5" stroke="white" />
        </div>
      </div>
      {/* Right */}
      <div className="w-full sm:w-2/3 md:w-5/12">
        <img src={hero} alt="hero" />
      </div>
    </div>
  );
};

export default HomeHero;
