import hero from "../assets/hero.jpg";

const Mission = () => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center mx-14 text-justify mb-16">
      <div className="w-full sm:w-2/3 md:w-5/12">
        <img src={hero} alt="mission" />
      </div>
      <div className="w-full md:w-1/">
        <h3 className="text-3xl mb-5">Our Mission</h3>
        <div className="text-md">
          As one person can’t change the world, but together we can make a
          difference. This is what we are “PRAYATNA”. Prayatna of change the
          life of those underprivileged children. Prayatna to bring smiles on
          those little faces. Prayatna to change the way how those kids looks at
          the world. We help our children grow and they let the child inside us
          live. We share their pieces of story with each other.
        </div>
        <ul className="text-md">
          <li>We smile, we fight</li>
          <li>We deal with the plight</li>
          <li>We love, to care</li>
          <li>We learn and we share.</li>
          <li>
            Take a experience of memory for lifetime, join us and buil a
            connection.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Mission;
