import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Events from "./pages/Events";
import About from "./pages/About";
import Team from "./pages/Team";
import PhotoGallery from "./pages/PhotoGallery";
import { createContext, useState } from "react";
import ContactModal from "./components/ContactModal";
// DUMMY COMMIT 2
export const AppContext = createContext();

function App() {
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  const openContactModal = () => setIsContactModalVisible(true);
  return (
    <AppContext.Provider value={{ openContactModal }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/events" element={<Events />} />
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<Team />} />
        <Route path="/photo-gallery" element={<PhotoGallery />} />
      </Routes>
      <ContactModal
        isVisible={isContactModalVisible}
        setIsVisible={setIsContactModalVisible}
      />
    </AppContext.Provider>
  );
}

export default App;
