import cx from "classnames";
import CloseIcon from "../assets/close.png";

const Modal = ({
  visible,
  hideModal,
  children,
  overlay,
  overlayClosable = true,
  padding,
}) => {
  return (
    <>
      {visible && (
        <>
          <div
            onClick={overlayClosable ? hideModal : null}
            className={cx(
              {
                "bg-black opacity-80": overlay,
              },
              "z-50 fixed inset-0 overflow-auto"
            )}
          />
          <div
            className={cx(
              "z-50 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-10 py-6 md:w-2/5 rounded-md max-w-2xl",
              padding
            )}
          >
            <div className="flex justify-end color-black pb-4">
              <img
                className="cursor-pointer"
                src={CloseIcon}
                onClick={hideModal}
                alt="close"
              />
            </div>
            {children}
          </div>
        </>
      )}
    </>
  );
};

export default Modal;
