import EventCard from "../components/EventCard";
import Layout from "../components/Layout";
import { events } from "../config/events";

const Events = () => {
  return (
    <Layout>
      <div className="2xl:max-w-7xl mx-14 2xl:mx-auto">
        <h2 className="text-3xl font-semibold text-gray-700 mb-6">Events</h2>
        <div className="flex flex-col md:flex-row flex-wrap justify-between items-center gap-x-4 gap-y-8 mb-8">
          {Object.keys(events)?.map((eventKey) => (
            <EventCard {...events[eventKey]} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Events;
