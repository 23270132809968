import React, { useEffect } from "react";
import Layout from "../components/Layout";
import LinearProgress from "../components/LinearProgress";
import Photo from "../components/Photo";
import { photoGallery } from "../config/photoGallery";

const PhotoGallery = () => {
  const [count, setCount] = React.useState(0);
  const initialLoadedArray = [false, false];
  const [loadedArray, setLoadedArray] = React.useState(initialLoadedArray);

  const onLoad = (index) => {
    setLoadedArray((loadedArray) => {
      const newLoadedArray = [...loadedArray];
      newLoadedArray[index] = true;
      return newLoadedArray;
    });
  };

  useEffect(() => {
    const trueCounts = loadedArray.filter((loaded) => loaded).length;
    if (trueCounts === count)
      setCount((count) => Math.min(count + 2, photoGallery.length));
  }, [loadedArray, count]);

  return (
    <Layout>
      <div className="mx-14">
        <h2 className="text-3xl text-center font-semibold text-gray-700 mb-6">
          Photo Gallery
        </h2>
        <div className="grid grid-cols-photo-gallery auto-rows-min gap-6 grid-flow-row-dense">
          {/* flexbox implementation :  <div className="flex flex-wrap gap-6 justify-center"> */}
          {photoGallery.slice(0, count).map((photo, index) => (
            <Photo key={index} {...photo} onLoad={() => onLoad(index)} />
          ))}
        </div>
        {count < photoGallery.length && <LinearProgress />}
      </div>
    </Layout>
  );
};

export default PhotoGallery;
