import React, { useContext } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AppContext } from "../App";

const Sidebar = () => {
  const { openContactModal } = useContext(AppContext);
  const [openState, setOpenState] = React.useState(false);
  const toggleSidebar = () => setOpenState((state) => !state);

  if (openState === false)
    return (
      <div onClick={toggleSidebar}>
        <FaBars />
      </div>
    );

  return (
    <div className="flex z-40 flex-col justify-around items-center text-text-blue fixed top-0 left-0 bg-white w-full h-full">
      <div className="fixed top-5 right-5 cursor-pointer">
        <FaTimes onClick={toggleSidebar} />
      </div>
      <Link to="/" className="cursor-pointer hover:text-orange-500">
        Home
      </Link>
      <Link to="/events" className="cursor-pointer hover:text-orange-500">
        Social Events
      </Link>
      <Link to="/team" className="cursor-pointer hover:text-orange-500">
        Team
      </Link>
      <Link
        to="/photo-gallery"
        className="cursor-pointer hover:text-orange-500"
      >
        Photo Gallery
      </Link>
      <div
        onClick={openContactModal}
        className="cursor-pointer hover:text-orange-500"
      >
        Contact
      </div>
    </div>
  );
};

export default Sidebar;
