import Layout from "../components/Layout";
import TeamMember from "../components/TeamMember";
import { team } from "../config/team";

const Team = () => {
  return (
    <>
      <Layout>
        <div className="mx-14">
          <h2 className="text-3xl text-center font-semibold text-gray-700 mb-6">
            Team
          </h2>
          <div className="flex flex-wrap justify-between gap-y-20 gap-x-4 my-20">
            {Object.keys(team).map((teamKey) => (
              <TeamMember {...team[teamKey]} />
            ))}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Team;
