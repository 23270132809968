import DevanshVerma from "../assets/images/team/DevanshVerma.jpeg";
import AyushiJain from "../assets/images/team/AyushiJ.jpeg";
import SanskritiDwivedi from "../assets/images/team/SanskritiDwivedi.jpeg";
import NishiPatodi from "../assets/images/team/NishiPatodi.jpeg";
import AnjaliNigade from "../assets/images/team/AnjaliNigade.jpeg";
import SanjanaRaghuwanshi from "../assets/images/team/SanjanaRaghuwanshi.jpeg";
export const team = {
  1: {
    name: "Ayushi Jain",
    image: AyushiJain,
    imageUrl: "",
    position: "President",
    description:
      "President looks after planning, organising and carrying out responsibilities. President also ensures that club members feel inspired,valuable and connected to each other.",
    socialLinks: {
      gmail: "chini.sanghi@gmail.com",
      linkedin: "https://www.linkedin.com/in/ayushi-jain27",
      instagram: "https://instagram.com/__srkian__",
    },
  },
  2: {
    name: "Devansh Verma",
    imageUrl: "",
    image: DevanshVerma,
    position: "Secratary",
    description:
      "Secretary maintains all the administrative records and upholds legal requirements. Secretary also ensures meetings are effectively organised and minuted.",
    socialLinks: {
      gmail: "deboverma@gmail.com",
      linkedin: "https://www.linkedin.com/in/devansh-verma-a4b244148/",
      instagram: "https://www.instagram.com/i_am_devansh_verma/",
    },
  },
  3: {
    name: "Anjali Nigade",
    image: AnjaliNigade,
    imageUrl: "",
    position: "PR Head",
    description:
      "PR head is responsible for carrying out tasks required for spreading out word about what we do.PR , being the visible part of club looks after promotional &amp; marketing activities and operates social media handles.",
    socialLinks: {
      gmail: "anjalinigade349@gmail.com",
      linkedin: "http://www.linkedin.com/in/anjalinigade349",
      instagram: "https://www.instagram.com/i_anjaliii___/",
    },
  },
  4: {
    name: "Sanjana Raghuwanshi",
    image: SanjanaRaghuwanshi,
    imageUrl: "",
    position: "Teaching Head",
    description:
      "Teaching head ensures preparation of course and booklets is done .Tracking the progress of the children and implementing new ideas also comes under responsibility of teaching head.",
    socialLinks: {
      gmail: "sanjanaraghu03@gmail.com",
      linkedin: "https://www.linkedin.com/in/sanjana-raghuwanshi-862aaa173",
      instagram: "https://www.instagram.com/_.sanjana03._/",
    },
  },
  5: {
    name: "Sanskriti Dwivedi",
    image: SanskritiDwivedi,
    imageUrl: "",
    position: "HR Head",
    description:
      "HR head is responsible for ensuring that effective database is maintained of club members. HR head also keeps an eye on performance of members and conducts interactive activities for smooth functioning of club.",
    socialLinks: {
      gmail: "dsanskriti0205@gmail.com",
      linkedin: "https://www.linkedin.com/in/sanskriti-dwivedi-692630166",
      instagram: "https://www.instagram.com/saanskratik/",
    },
  },
  6: {
    name: "Nishi Patodi",
    image: NishiPatodi,
    imageUrl: "",
    position: "R&D Head",
    description:
      "Research and development Head is responsible for ensuring that the club meet its objectives of developing new schemes or ideas or processes to improve.",
    socialLinks: {
      gmail: "patodi@gmail.com",
      linkedin: "https://www.linkedin.com/in/nishi-patodi-2603/",
      instagram: "instagram.com/nishipatodi",
    },
  },
};
