import Layout from "../components/Layout";
import HomeHero from "../components/HomeHero";
import Vision from "../components/Vision";
import Mission from "../components/Mission";
import DocumentaryCTA from "../components/DocumentaryCTA";

const Home = () => {
  return (
    <Layout>
      {/* Hero Section */}
      <HomeHero />

      <div className="bg-gray-100 text-gray-700 py-16 mb-16">
        <div className="flex justify-around">
          <div className="flex flex-col items-center">
            <div className="flex items-end">
              <span className="text-4xl">1200</span>
              <span className="text-2xl">+</span>
            </div>
            <div className="text-lg">Children</div>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex items-end">
              <span className="text-4xl">109</span>
              <span className="text-2xl">+</span>
            </div>
            <div className="text-lg">Members</div>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex items-end">
              <span className="text-4xl">18</span>
              <span className="text-2xl">+</span>
            </div>
            <div className="text-lg">Schools</div>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex items-end">
              <span className="text-4xl">7</span>
              <span className="text-2xl">+</span>
            </div>
            <div className="text-lg">Villages</div>
          </div>
        </div>
      </div>

      <DocumentaryCTA />
      <Vision />
      <Mission />
    </Layout>
  );
};

export default Home;
