import React from "react";
import {
  FaFacebookF,
  FaYoutube,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";

const Socials = () => {
  return (
    <div className="flex items-center gap-2 cursor-pointer">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.youtube.com/channel/UCXXhDACCB8UTGsoGgkh1drA/featured"
      >
        <FaYoutube />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/prayatnasvvv/"
      >
        <FaFacebookF />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.linkedin.com/company/prayatna-aspring-dreams/?originalSubdomain=in"
      >
        <FaLinkedinIn />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/prayatna_indore/?hl=en"
      >
        <FaInstagram />
      </a>
    </div>
  );
};

export default Socials;
