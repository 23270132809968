import { useState } from "react";
import emailjs from "emailjs-com";

import Modal from "./Modal";
import { ReactComponent as Home } from "../assets/home.svg";

const ContactModal = ({ isVisible, setIsVisible }) => {
  const [contactForm, setContactForm] = useState({
    message: "",
    from_name: "",
    from_email: "",
    subject: "",
  });

  const onChange = (e) => {
    setContactForm({ ...contactForm, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    console.log(e.target);
    e.preventDefault(); // Prevents default refresh by the browser
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          alert("The mail was sent successfully!");
          setIsVisible(false);
        },
        (error) => {
          alert(error.text);
          setIsVisible(false);
        }
      );
  };
  return (
    <Modal
      overlayClosable
      overlay
      visible={isVisible}
      hideModal={() => {
        setIsVisible(false);
      }}
    >
      <div className="text-2xl text-text-blue mb-4">Get in Touch</div>

      <div className="flex items-start mb-4">
        <div className="w-6 mr-4">
          <Home className="w-full" />
        </div>
        <div>
          <div className="text-gray-800 text-base">
            Shri Vaishnav Vidyapeeth Vishwavidyalaya Campus
          </div>
          <div className="text-gray-400 text-sm">Indore – Ujjain Road,</div>
          <div className="text-gray-400 text-sm">Indore – 453111</div>
        </div>
      </div>
      <div className="w-full mr-36">
        <form onSubmit={onSubmit} className="flex flex-col gap-2 text-sm">
          <div className="w-full">
            <textarea
              placeholder="Enter Message"
              className="w-full border p-2"
              name="message"
              value={contactForm.message}
              onChange={onChange}
            ></textarea>
          </div>
          <div className="w-full flex">
            <input
              className="w-1/2 border mr-1 p-2"
              placeholder="Name"
              name="from_name"
              value={contactForm.from_name}
              onChange={onChange}
            />
            <input
              className="w-1/2 border ml-1 p-2"
              placeholder="Email"
              name="from_email"
              value={contactForm.from_email}
              onChange={onChange}
            />
          </div>
          <div className="w-full">
            <input
              className="w-full border p-2"
              placeholder="Subject"
              name="subject"
              value={contactForm.subject}
              onChange={onChange}
            />
          </div>
          <div>
            <button
              type="submit"
              className="bg-gray-700 cursor-pointer text-white rounded-md text-center my-4 py-2 px-16"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ContactModal;
