import { FaGoogle, FaLinkedin, FaInstagram } from "react-icons/fa";
const TeamMember = ({
  name,
  imageUrl,
  position,
  description,
  image,
  socialLinks,
}) => {
  return (
    <div className="flex flex-col w-full md:w-5/12 lg:w-1/4 md:min-w-18 md:max-w-sm shadow-lg px-4 pb-4">
      <div className="w-28 mx-auto">
        <img
          className="w-full rounded-full"
          src={imageUrl || image}
          alt="team member"
        />
      </div>
      <div className="text-2xl text-center font-semibold">{name}</div>
      <div className="text-sm text-center">{position}</div>
      <div className="text-sm leading-tight text-center my-3">
        {description}
      </div>
      <div className="flex justify-center gap-4">
        <a
          target="_blank"
          rel="noreferrer"
          href={socialLinks.gmail && `mailto: ${socialLinks.gmail}`}
        >
          <FaGoogle />
        </a>
        <a target="_blank" rel="noreferrer" href={socialLinks.linkedin}>
          <FaLinkedin />
        </a>
        <a target="_blank" rel="noreferrer" href={socialLinks.instagram}>
          <FaInstagram />
        </a>
      </div>
    </div>
  );
};

export default TeamMember;
