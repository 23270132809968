const About = () => {
  return (
    <div class="mt-16 lg:container mx-auto text-center text-gray-700">
      <div class="text-3xl md:text-4xl font-bold">
        <span class="text-teal-600">Extraordinary tools</span> for extraordinary
        talent
      </div>

      <p class="text-xl mb-10">
        We're helping photographers to{" "}
        <span class="text-teal-600">grow their business</span> and{" "}
        <span class="text-red-600">develop their skills</span>
      </p>

      <button
        type="button"
        href="#"
        class="btn rounded-full bg-teal-600 text-lg text-white py-3 px-12 font-semibold mb-12"
      >
        {" "}
        Find Out More
      </button>
    </div>
  );
};

export default About;
