import React from "react";

const Photo = (props) => {
  const imageTypeClassname = {
    square: "col-span-4 row-span-4",
    horizontal: "row-span-2 col-span-4",
    vertical: "row-span-4 md:col-span-2 col-span-4",
  };

  return (
    <div
      className={`
        ${imageTypeClassname[props.type]}
        relative h-auto overflow-hidden 
        rounded-lg shadow-lg
      `}
    >
      <img
        onLoad={props.onLoad}
        src={props.imageUrl}
        className={`w-full h-full object-cover`}
        alt={props.heading}
      />
      {props.heading && props.description && (
        <div className="z-10 absolute w-full h-full flex opacity-0 transition-all ease-in-out duration-700 hover:opacity-100 hover:backdrop-blur-3xl top-0 justify-center items-center flex-col gap-4 p-6">
          <h2 className="text-2xl">{props.heading}</h2>
          <p>{props.description}</p>
        </div>
      )}
    </div>
  );
};

export default Photo;
