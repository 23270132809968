export const events = {
  1: {
    imageUrl:
      "https://assets.meero.com/meeroshowcase/images/front/V5/talent/meero-jobs1.svg",
    eventName: "Open Mic",
    eventSubTitle: "More Speech. More Freedom.",
    eventDescription: "Engage with community and help us bring out the best",
  },
  2: {
    imageUrl:
      "https://assets.meero.com/meeroshowcase/images/front/V5/talent/mymeero1.svg",
    eventName: "Open Mic",
    eventSubTitle: "More Speech. More Freedom.",
    eventDescription: "Engage with community and help us bring out the best",
  },
  3: {
    imageUrl:
      "https://assets.meero.com/meeroshowcase/images/front/V5/talent/community1.svg",
    eventName: "Open Mic",
    eventSubTitle: "More Speech. More Freedom.",
    eventDescription: "Engage with community and help us bring out the best",
  },
  4: {
    imageUrl:
      "https://assets.meero.com/meeroshowcase/images/front/V5/talent/community1.svg",
    eventName: "Open Mic",
    eventSubTitle: "More Speech. More Freedom.",
    eventDescription: "Engage with community and help us bring out the best",
  },
};
