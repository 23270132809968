import { useEffect, useState } from "react";

const LinearProgress = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const i = setInterval(function () {
      setProgress((p) => (p + 1) % 100);
    }, 10);
    return () => {
      clearInterval(i);
    };
  }, []);

  return (
    <div className="w-full bg-gray-200 h-2 mt-6 relative overflow-hidden">
      <div
        className={`bg-blue-500 h-2 absolute w-1/2`}
        style={{ left: progress + "%" }}
      ></div>
    </div>
  );
};

export default LinearProgress;
