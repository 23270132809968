import hero from "../assets/hero.jpg";

const Vision = () => {
  return (
    <div className="flex flex-col-reverse md:flex-row justify-between items-center mx-14 text-justify mb-16">
      <div className="w-full md:w-1/2">
        <h3 className="text-3xl mb-5">Our Vision</h3>
        <div className="text-md">
          Education breeds confidence. Confidence breeds hope. Hope breeds
          peace. We believe that knowledge gives children the power to dream of
          a better future and the confidence needed to pursue a full education
          which in turn will help generations to come. The idea is to share what
          we possess "SHIKSHA" and in the process, inspire people around us to
          deliver to those who need it the most.
        </div>
      </div>
      <div className="w-full sm:w-2/3 md:w-5/12">
        <img src={hero} alt="hero" />
      </div>
    </div>
  );
};

export default Vision;
