import { useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import logo from "../assets/slogo.jpeg";
import { AppContext } from "../App";
import Sidebar from "./Sidebar";
import Socials from "./Socials";

const Navbar = () => {
  let navigate = useNavigate();
  const { openContactModal } = useContext(AppContext);

  return (
    <>
      <div className="mx-14">
        <div className="flex text-text-light my-4 text-sm flex-wrap gap-2">
          <div className="">Phone: +91-8965037900</div>
          <div className="w-px my-0.5 bg-text-light" />
          <div className="">Email: prayatna.svvv@gmail.com</div>
          <div className="w-px my-0.5 bg-text-light" />
          <Socials />
        </div>
        <hr style={{ color: "#f0f1f2" }} />
        <div className="flex justify-between items-center my-6">
          {/* Prayatna Logo */}
          <div className="w-14 cursor-pointer" onClick={() => navigate("/")}>
            <img src={logo} alt="prayatna" />
          </div>
          <div className="gap-6 text-text-blue sm:flex hidden">
            <Link to="/" className="cursor-pointer hover:text-orange-500">
              Home
            </Link>
            <Link to="/events" className="cursor-pointer hover:text-orange-500">
              Social Events
            </Link>
            <Link to="/team" className="cursor-pointer hover:text-orange-500">
              Team
            </Link>
            <Link
              to="/photo-gallery"
              className="cursor-pointer hover:text-orange-500"
            >
              Photo Gallery
            </Link>
            <div
              onClick={openContactModal}
              className="cursor-pointer hover:text-orange-500"
            >
              Contact
            </div>
          </div>
          <div className="gap-6 text-text-blue sm:hidden flex">
            <Sidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
